<template>
<div id="no_header__layout">
  <Toast position="top-center" />
    <slot />
    <Footer/>
</div>
</template>

<script>
import Footer from '@/components/Footer'
import Toast from "primevue/toast";
export default {
  name: "NoHeaderLayout",
  components: {
    Footer,
    Toast
  }
}
</script>

<style scoped>

</style>
