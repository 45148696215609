import { render, staticRenderFns } from "./NoHeaderLayout.vue?vue&type=template&id=28e3764f&scoped=true&"
import script from "./NoHeaderLayout.vue?vue&type=script&lang=js&"
export * from "./NoHeaderLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e3764f",
  null
  
)

export default component.exports